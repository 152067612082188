import { ReactNode } from 'react';

// Changed from const enum to regular enum since we need to use Object.values
export enum FieldTypes {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  BUTTON = 'button',
  SWITCH = 'switch',
  DATEPICKER = 'datepicker',
  FIELD_MAPPING = 'field_mapping',
  READONLY_FIELD_MAPPING = 'readonly_field_mapping'
}

export interface FieldTypeOption {
  value: FieldTypes;
  label: string | ReactNode;
}

export const FIELD_TYPE_OPTIONS: FieldTypeOption[] = [
  { value: FieldTypes.TEXT, label: 'Text' },
  { value: FieldTypes.TEXTAREA, label: 'Text Area' },
  { value: FieldTypes.PASSWORD, label: 'Password' },
  { value: FieldTypes.CHECKBOX, label: 'Checkbox' },
  { value: FieldTypes.BUTTON, label: 'Button' },
  { value: FieldTypes.SWITCH, label: 'Switch' },
  { value: FieldTypes.DATEPICKER, label: 'DatePicker' },
  { value: FieldTypes.FIELD_MAPPING, label: 'Field Mapping' },
  { value: FieldTypes.READONLY_FIELD_MAPPING, label: 'Readonly Field Mapping' }
];

// Base interface for mapping fields
export interface MappingRow {
  sourceField: string;
  targetField: string;
}

// Location information for a field within the form structure
export interface FieldLocation {
  tab: string;
  tabIndex: number;
  fieldGroupIndex: number;
  fieldIndex: number;
}

// Base field properties without location information
export interface Field {
  name: string;
  fieldType: FieldTypes;
  required: boolean;
  readonly: boolean;
  useDefaultValue: boolean;
  defaultValue: string;
  onClick?: string;
  rows?: MappingRow[];
  sourceHeader?: string;
  targetHeader?: string;
  manualInputOptionAllowed?: boolean;
}

// Complete field properties including location information
export interface FieldBlockProps extends Field, FieldLocation {}

// Type guard to check if a field is a mapping field
export const isMappingField = (fieldType: FieldTypes): boolean => {
  return fieldType === FieldTypes.FIELD_MAPPING || 
         fieldType === FieldTypes.READONLY_FIELD_MAPPING;
};

// Type guard to check if a field can have a required property
export const canBeRequired = (fieldType: FieldTypes): boolean => {
  return fieldType !== FieldTypes.CHECKBOX &&
         fieldType !== FieldTypes.BUTTON;
};